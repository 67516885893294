import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
// import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";

import { APP_ROUTES } from "../../util/routes/appRoutes";
import { LocalStorage } from "../../util/storage/localStorage.service";
import { ApiRoutes } from "src/app/util/routes/apiRoutes";

@Injectable()
export class AuthService {
	private authToken!: string | null;
	private authState: BehaviorSubject<boolean>;
	private initialData: string[] = ["pra-admin-token"];

	constructor(
		private http: HttpClient,
		private router: Router,
		private ls: LocalStorage
	) {
		this.authState = new BehaviorSubject(false);
		this.initialData.forEach((value) => {
			if (value === "pra-admin-token") {
				this.authToken = this.ls.getItem(value);
			}
		});
	}

	/**
	 * Login action
	 * @param payload is credentials of the user
	 */
	public login(payload: any) {
		// this.ls.setItem("qudos-admin-token", "jwt-token");
		// this.token = "jwt-token";
		const url: string = ApiRoutes.login;
		return this.http.post(url, payload).pipe(
			map((data: any) => {
				if (data.status == 200) {
					this.ls.setItem("pra-admin-token", data.token);
					// this.ls.setItem("pra-admin-currentUser", JSON.stringify(data));
					// this.router.navigate(["/dashboard"]);
					this.token = data.token;
				}
				return data;
			})
		);
	}

	public sendOtp(payload: {user_id: number, otp_type: 'email' | 'mobile'}) {
		const url: string = ApiRoutes.sendOtp;
		return this.http.post(url, payload);
	}

	public verifyOtp(payload: {user_id: number, otp_type: 'email' | 'mobile', otp: string}) {
		const url: string = ApiRoutes.verifyOtp;
		return this.http.post(url, payload);
	}

	public forgotPassword(payload: {email?: string, mobile?: string, user_type: string}) {
		const url: string = ApiRoutes.forgetPassword;
		return this.http.post(url, payload);
	}

	public setPassword(payload: {verification_token: string, password: string }) {
		const url: string = ApiRoutes.setPassword;
		return this.http.post(url, payload);
	}

	//* get decoded token */
	// public getToken(): string {
	// 	const helper = new JwtHelperService();
	// 	return helper.decodeToken(this.token);
	// }

	/**
	 * Login action
	 * @param token is JWT token
	 */
	public set token(token: string | null) {
		this.authToken = token;
		this.changeAuthState = !!token;
	}

	//* get auth token */
	public get token(): string {
		return this.authToken ? this.authToken : "";
	}

	/**
	 * Change auth state of a user
	 * @param newState of the user
	 */
	public set changeAuthState(newState: boolean) {
		this.authState.next(newState);
	}

	//** Method to check if user logged in */
	public isAuthenticated(): boolean {
		return !!this.token;
	}

	//** Logiut action */
	public logout(reload: boolean = true): void {
		this.ls.clear();
		this.token = null;
		this.changeAuthState = !!this.token;
		if (reload) {
			window.location.reload();
		} else {
			this.router.navigate([APP_ROUTES.login]);
		}
	}
}
