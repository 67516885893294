import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from './util/routes/appRoutes';
import { AuthGuard } from './@core/guards/auth-guard.service';
import { UnAuthGuard } from './@core/guards/unauth-guard.service';

const routes: Routes = [
	{
		path: APP_ROUTES.root,
		redirectTo: APP_ROUTES.login,
		pathMatch: 'full'
	},
	{
		path: APP_ROUTES.root,
		loadChildren: () => import('./pages/auth-pages/auth-pages.module').then(m => m.AuthPagesModule),
		canActivate: [UnAuthGuard]
	},
	{
		path: APP_ROUTES.root,
		loadChildren: () => import('./pages/main-pages/main-pages.module').then(m => m.MainPagesModule),
		canActivate: [AuthGuard]
	}	
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
